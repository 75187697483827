// extracted by mini-css-extract-plugin
export var bullet__a7QBq = "PlasmicPlan-module--bullet__a7QBq--bFfdk";
export var bullet__gafIx = "PlasmicPlan-module--bullet__gafIx--4xyC6";
export var bullet__lw9M = "PlasmicPlan-module--bullet__lw9M--upj83";
export var button__tZtbZ = "PlasmicPlan-module--button__tZtbZ---178Y";
export var freeBox___93O1P = "PlasmicPlan-module--freeBox___93O1P--0GdIU";
export var freeBox__avM3F = "PlasmicPlan-module--freeBox__avM3F--gGExF";
export var freeBox__kL5JX = "PlasmicPlan-module--freeBox__kL5JX--n2N9N";
export var freeBox__o76S = "PlasmicPlan-module--freeBox__o76S--Q8hc7";
export var root = "PlasmicPlan-module--root--CHHJ+";
export var slotTargetName = "PlasmicPlan-module--slotTargetName--Eh4kH";
export var svg__b1IV0 = "PlasmicPlan-module--svg__b1IV0--XyVrn";
export var svg__gpqGz = "PlasmicPlan-module--svg__gpqGz--Tff98";
export var svg__jXZB = "PlasmicPlan-module--svg__jXZB--bK9+e";
export var text__nVaLc = "PlasmicPlan-module--text__nVaLc--DwqBD";