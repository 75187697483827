// extracted by mini-css-extract-plugin
export var bullet__bxD9U = "PlasmicPricing-module--bullet__bxD9U--JAcCg";
export var bullet__fzUq = "PlasmicPricing-module--bullet__fzUq--4KtGt";
export var bullet__lCuXy = "PlasmicPricing-module--bullet__lCuXy--RX9iy";
export var bullet__mgq1P = "PlasmicPricing-module--bullet__mgq1P--LrgY7";
export var bullet__q6MDz = "PlasmicPricing-module--bullet__q6MDz--gP2yi";
export var bullet__qc37W = "PlasmicPricing-module--bullet__qc37W--Y-veP";
export var bullet__rmb7E = "PlasmicPricing-module--bullet__rmb7E--ziouZ";
export var bullet__tC8D = "PlasmicPricing-module--bullet__tC8D--M+nsW";
export var bullet__tIzQg = "PlasmicPricing-module--bullet__tIzQg--uQOT+";
export var bullet__ytb9Z = "PlasmicPricing-module--bullet__ytb9Z--NPLpF";
export var button__moMHv = "PlasmicPricing-module--button__moMHv--4gREL";
export var button__oulnc = "PlasmicPricing-module--button__oulnc--PePbr";
export var button__xoAkQ = "PlasmicPricing-module--button__xoAkQ--Ne8Hd";
export var column___33Hul = "PlasmicPricing-module--column___33Hul--wFvyB";
export var column___7E1Mv = "PlasmicPricing-module--column___7E1Mv--Esbbl";
export var column__tZvbk = "PlasmicPricing-module--column__tZvbk--DA7mj";
export var columns = "PlasmicPricing-module--columns--Hmjvj";
export var faqSection = "PlasmicPricing-module--faqSection--VphoN";
export var faq__ulmjC = "PlasmicPricing-module--faq__ulmjC--W5DIA";
export var faq__uwuE = "PlasmicPricing-module--faq__uwuE--KmpMx";
export var footer = "PlasmicPricing-module--footer--jDrNw";
export var freeBox__gXbyi = "PlasmicPricing-module--freeBox__gXbyi--XXHxr";
export var freeBox__oBbUl = "PlasmicPricing-module--freeBox__oBbUl--bax4-";
export var freeBox__pBwmw = "PlasmicPricing-module--freeBox__pBwmw--j-A8e";
export var freeBox__uxm0R = "PlasmicPricing-module--freeBox__uxm0R--jSzFa";
export var header = "PlasmicPricing-module--header--S2TXl";
export var plan___5E1F = "PlasmicPricing-module--plan___5E1F--uaSqA";
export var plan__aybJu = "PlasmicPricing-module--plan__aybJu--s63zH";
export var plan__zDgFl = "PlasmicPricing-module--plan__zDgFl--KDGlZ";
export var pricingSection = "PlasmicPricing-module--pricingSection--Tqbgl";
export var root = "PlasmicPricing-module--root--pYMNq";
export var svg___23RlO = "PlasmicPricing-module--svg___23RlO--UPzNW";
export var svg__alAJz = "PlasmicPricing-module--svg__alAJz--h9eL6";
export var svg__mv5Ui = "PlasmicPricing-module--svg__mv5Ui--p2d0U";
export var svg__ngnmH = "PlasmicPricing-module--svg__ngnmH--GBvqg";
export var svg__phV1G = "PlasmicPricing-module--svg__phV1G--PKs2s";
export var svg__w1D15 = "PlasmicPricing-module--svg__w1D15--6V5Q0";
export var text___4Vjib = "PlasmicPricing-module--text___4Vjib--oYHG8";
export var text___79YEn = "PlasmicPricing-module--text___79YEn--HAr-1";
export var text__qwzJj = "PlasmicPricing-module--text__qwzJj--si6+S";